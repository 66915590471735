import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

const BetReportPage = lazy(() => import('../../container/pages/BetReportPage'));
const BetReportClient = lazy(() => import('../../container/pages/result/BetReportClientPage'));


function BetReportRoute() {
  return (
    <Routes>
      <Route index element={<BetReportPage />} />
      <Route path="/client" element={<BetReportClient />} />
    </Routes>
  );
}

export default BetReportRoute;
