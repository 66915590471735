import Cookies from 'js-cookie';
import { apiClient } from "./apiClient";

export const getAllMembers = async (filter = '') => {
    if (filter !== '') {
        filter = `?${filter}`;
    }

    const response = await apiClient.get(`/member${filter}`, {
        headers: { Authorization: `Bearer ${Cookies.get('access_token')}` }
    });

    return response;
}

export const createMember = async (member) => {
    const response = await apiClient.post('/member', member, {
        headers: { Authorization: `Bearer ${Cookies.get('access_token')}` }
    });

    return response;
}

export const updateMember = async (id, member) => {
    const response = await apiClient.patch(`/member/${id}`, member, {
        headers: { Authorization: `Bearer ${Cookies.get('access_token')}` }
    });

    return response;
}

export const deleteMember = async (id) => {
    const response = await apiClient.delete(`/member/${id}`, {
        headers: { Authorization: `Bearer ${Cookies.get('access_token')}` }
    });
    return response;
}

export const updateCredit = async (memberId, amount) => {
    const response = await apiClient.post('/member/update-credit', { memberId, amount }, {
        headers: { Authorization: `Bearer ${Cookies.get('access_token')}` }
    });

    return response;
}

export const getClientResult = async (filter) => {
    const response = await apiClient.get(`/periods/memberResult/${filter}`, {
        headers: { Authorization: `Bearer ${Cookies.get('access_token')}` }
    });

    return response;
}

export const changePassword = async (data) => {
    const response = await apiClient.post('/auth/change-password', data, {
        headers: { Authorization: `Bearer ${Cookies.get('access_token')}` }
    });

    return response;   
}

export const createMemberAnnouncer = async (announcer) => {
    const response = await apiClient.post('/member/announcer', announcer, {
        headers: { Authorization: `Bearer ${Cookies.get('access_token')}` }
    });

    return response;
}

export const createMemberSupporter = async (supporter) => {
    const response = await apiClient.post('/member/supporter', supporter, {
        headers: { Authorization: `Bearer ${Cookies.get('access_token')}` }
    });

    return response;
}