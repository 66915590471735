import { createSlice } from '@reduxjs/toolkit';
// import { login } from '../services/authService';
// import { getItem, removeItem, setItem } from '../utility/localStorageControl';
import Cookies from 'js-cookie';


const initialState = {
    logedIn: Cookies.get('logedIn'),
    user: null,
    loading: false,
    error: null,
};

export const AuthSlice = createSlice({
    name: 'Auth',
    initialState,
    reducers: {
        setAuthLoading: (state, action) => {
            state.loading = action.payload;
        },
        setLogedIn: (state, action) => {
            Cookies.set('logedIn', true);
            Cookies.set('access_token', action.payload.token);
            Cookies.set('name', action.payload.nickname);
            Cookies.set('account', action.payload.account);
            Cookies.set('share', action.payload.share);
            Cookies.set('memberId', action.payload.rank.name === 'supporter' ? action.payload.grant : action.payload.id);
            Cookies.set('role', action.payload.rank.name);
            Cookies.set('permissions', action.payload.permissions);
            
            state.logedIn = true;
            state.error = null;
            state.user = null;
        },
        logout: (state) => {
            Cookies.remove('logedIn');
            Cookies.remove('access_token');
            Cookies.remove('name');
            Cookies.remove('account');
            Cookies.remove('memberId');
            Cookies.remove('role');
            Cookies.remove('permissions');

            state.logedIn = Cookies.get('logedIn');
            state.user = null;
            state.error = null;
        }
    }
});

export const { setLogedIn, logout, setAuthLoading } = AuthSlice.actions;
export default AuthSlice.reducer;