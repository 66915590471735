import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

const MainPage = lazy(() => import('../../container/pages/MainPage'));
const RulePage = lazy(() => import('../../container/pages/RulePage'));
const SupporterPage = lazy(() => import('../../container/pages/SupporterPage'));
const HeaderPage = lazy(() => import('../../container/pages/HeaderPage'));
const AgentPage = lazy(() => import('../../container/pages/AgentPage'));
const MemberPage = lazy(() => import('../../container/pages/MemberPage'));
const AnnoncePage = lazy(() => import('../../container/pages/AnnouncePage'));
const LottoResultPage = lazy(() => import('../../container/pages/LottoResultPage'));
const RateSettingPage = lazy(() => import('../../container/pages/RateSettingPage'));
const AnnouncerPage = lazy(() => import('../../container/pages/AnnouncerPage'));
const SettingPage = lazy(() => import('../../container/pages/Setting'));

function PagesRoute() {
  return (
    <Routes>
      <Route index element={<MainPage />} />
      <Route path="/rule" element={<RulePage />} />
      <Route path="/setting" element={<SettingPage />} />
      <Route path="/supporter" element={<SupporterPage />} />
      <Route path="/header" element={<HeaderPage />} />
      <Route path="/agent" element={<AgentPage />} />
      <Route path="/member" element={<MemberPage />} />
      <Route path="/announce" element={<AnnoncePage />} />
      <Route path="/lotto-result" element={<LottoResultPage />} />
      <Route path="/rate" element={<RateSettingPage />} />
      <Route path="/announcer" element={<AnnouncerPage />} />
    </Routes>
  );
}

export default PagesRoute;
