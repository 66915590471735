import { Menu } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';

import UilEllipsisV from '@iconscout/react-unicons/icons/uil-ellipsis-v';
import propTypes from 'prop-types';
import { AgentRoleMenu, AnnouncerRoleMenu, HeaderRoleMenu, SuperAdminRoleMenu, SupporterRoleMenu } from './MenuByRole';
import { utils } from '../service/utils';

const itemsByRole = (toggleCollapsed, path, topMenu, role) => {

  if (role === 'super-admin') {
    return SuperAdminRoleMenu(toggleCollapsed, path, topMenu);
  }

  if (role === 'announcer') {
    return AnnouncerRoleMenu(toggleCollapsed, path, topMenu);
  }

  if (role === 'header') {
    return HeaderRoleMenu(toggleCollapsed, path, topMenu);
  }

  if (role === 'agent') {
    return AgentRoleMenu(toggleCollapsed, path, topMenu);
  }

  if (role === 'supporter') {
    return SupporterRoleMenu(toggleCollapsed, path, topMenu);
  }
}

function MenuItems({ toggleCollapsed }) {
  const { role } = utils.getRoleAndPermissions();

  const { topMenu } = useSelector((state) => {
    return {
      topMenu: state.ThemeLayout.topMenu,
    };
  });

  const path = '/system';
  const pathName = window.location.pathname;
  const pathArray = pathName && pathName !== '/' ? pathName.split(path) : [];
  const mainPath = pathArray.length > 1 ? pathArray[1] : '';
  const mainPathSplit = mainPath.split('/');

  const [openKeys, setOpenKeys] = React.useState(
    !topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : [],
  );

  const onOpenChange = (keys) => {
    setOpenKeys(keys[keys.length - 1] !== 'recharts' ? [keys.length && keys[keys.length - 1]] : keys);
  };

  const onClick = (item) => {
    if (item.keyPath.length === 1) setOpenKeys([]);
  };

  const items = itemsByRole(toggleCollapsed, path, topMenu, role);

  return (
    <Menu
      onOpenChange={onOpenChange}
      onClick={onClick}
      mode={!topMenu || window.innerWidth <= 991 ? 'inline' : 'horizontal'}
      // // eslint-disable-next-line no-nested-ternary
      defaultSelectedKeys={
        !topMenu
          ? [
            `${mainPathSplit.length === 1 ? 'home' : mainPathSplit.length === 2 ? mainPathSplit[1] : mainPathSplit[2]
            }`,
          ]
          : []
      }
      defaultOpenKeys={!topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : []}
      overflowedIndicator={<UilEllipsisV />}
      openKeys={openKeys}
      items={items}
    />
  );
}

MenuItems.propTypes = {
  toggleCollapsed: propTypes.func,
};

export default MenuItems;
