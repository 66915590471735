// import { CheckOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { notification } from "antd";

const Notification = (type, description) => {
    if (type === 'success') {
        notification.success({
            message: 'ข้อความจากระบบ',
            description,
            duration: 5,
            // icon: <CheckOutlined style={{ color: '#52c41a' }} />
        });
    } else if (type === 'error') {
        notification.error({
            message: 'ข้อความจากระบบ',
            description,
            duration: 5,
            // icon: <CloseCircleOutlined style={{ color: '#ff4d4f' }} />
        });
    }
};

export default Notification;