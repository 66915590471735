import { configureStore } from "@reduxjs/toolkit";
import AuthSlice from "./authSlice";
import ThemeLayoutSlice from "./themeLayoutSlice";

export const store = configureStore({
    reducer: {
        Auth: AuthSlice,
        ThemeLayout: ThemeLayoutSlice,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
})