import leftPad from 'left-pad';
import moment from 'moment';
import Cookies from 'js-cookie';
// import { LottoPermission } from '../pages/member/member.type';

export const utils = {
    get10Number() {
        const data = [];
        for (let index = 0; index < 10; index++) {
            data.push(index);
        }

        return data;
    },
    get10NumberWithTotalPrice() {
        const data = [];
        for (let index = 0; index < 10; index++) {
            data.push({
                numbers: index,
                total: 0.00,
                betDataMaster: null
            });
        }

        return data;
    },
    get100Number() {
        const data = [];
        for (let index = 0; index < 100; index++) {
            data.push(leftPad(index, 2, '0'));
        }

        return data;
    },
    get100NumberWithTotalPrice() {
        const data = [];
        for (let index = 0; index < 100; index++) {
            data.push({
                numbers: leftPad(index, 2, '0'),
                total: 0.00,
                betDataMaster: null
            });
        }

        return data;
    },
    get1000Number() {
        const data = [];
        for (let index = 0; index < 1000; index++) {
            data.push(leftPad(index, 3, '0'));
        }
        return data;
    },
    get1000NumberWithTotalPrice() {
        const data = [];
        for (let index = 0; index < 1000; index++) {
            data.push({
                numbers: leftPad(index, 3, '0'),
                total: 0.00,
                betDataMaster: null
            });
        }

        return data;
    },
    permute(xs) {

        const ret = [];

        for (let i = 0; i < xs.length; i += 1) {
            const rest = utils.permute(xs.slice(0, i).concat(xs.slice(i + 1)));

            if (!rest.length) {
                ret.push([xs[i]])
            } else {
                for (let j = 0; j < rest.length; j += 1) {
                    ret.push([xs[i]].concat(rest[j]))
                }
            }
        }

        return ret;
    },
    numberWithCommas(x) {
        if (Number.isNaN(x) || !x) return "0.00";
        const value = parseFloat(x).toFixed(2);
        const num = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return num;
    },
    random(num, pad) {
        const n = num - 1;
        const rand = Math.floor(Math.random() * (n));
        return leftPad(rand, pad, '0');
    },
    getFirstDayOfWeek(d) {
        // clone date object, so we don't mutate it
        const date = new Date(d);
        const day = date.getDay(); // get day of week

        // day of month - day of week (-6 if Sunday), otherwise +1
        const diff = date.getDate() - day + (day === 0 ? -6 : 1);

        return new Date(date.setDate(diff));
    },
    getWeek(status) {
        const today = new Date();

        const firstday = this.getFirstDayOfWeek(today);

        // Get the last day of the current week (Sunday)
        const lastday = new Date(firstday);
        lastday.setDate(lastday.getDate() + 6);

        // get current week
        if (status === 'current-week') {
            return {
                startDate: moment(firstday).format("YYYY-MM-DD 00:00:00"),
                endDate: moment(lastday).format("YYYY-MM-DD 23:59:59"),
            }
        }

        // get last week
        return {
            startDate: moment(firstday).add(-7, 'd').format("YYYY-MM-DD 00:00:00"),
            endDate: moment(lastday).add(-7, 'd').format("YYYY-MM-DD 23:59:59"),
        }
    },
    getMonth(status) {
        const today = new Date();

        if (status === 'current-month') {
            // คำนวณวันที่เริ่มต้นและสิ้นสุดของเดือนที่กำหนด
            const currentMonthStart = moment(today).startOf('month');
            const currentMonthEnd = moment(today).endOf('month');

            return {
                startDate: moment(currentMonthStart).format("YYYY-MM-DD 00:00:00"),
                endDate: moment(currentMonthEnd).format("YYYY-MM-DD 23:59:59"),
            }
        }

        // คำนวณวันที่เริ่มต้นและสิ้นสุดของเดือนก่อนหน้า
        const previousMonthStart = moment(today).subtract(1, 'month').startOf('month');
        const previousMonthEnd = moment(today).subtract(1, 'month').endOf('month');

        return {
            startDate: moment(previousMonthStart).format("YYYY-MM-DD 00:00:00"),
            endDate: moment(previousMonthEnd).format("YYYY-MM-DD 23:59:59")
        };
    },
    getRoleAndPermissions() {
        return {
            role: Cookies.get('role'),
            permissions: Cookies.get('permissions'),
        }
    },
    getLottoPermissions(isObject = false) {
        const permissions = Cookies?.get('permissions');

        if (isObject) {
            return JSON.parse(permissions).map((v) => {
                return v.lottoMaster;
            });
        }

        return JSON.parse(permissions).map((v) => {
            return v.lottoMaster?.id
        });
    },
    removeDuplicatesWithArray(arr) {
        const seen = new Set();
        const dup = arr.filter(obj => {
            const key = JSON.stringify(obj); // Customize key based on property comparison
            if (!seen.has(key)) {
                seen.add(key);
                return true;
            }
            return false;
        });

        return dup;
    },
    numberToShorthand(number) {
        const suffixes = ['K', 'M', 'B', 'T'];
        const suffixNum = Math.floor((`${number}`).length / 4);

        if (suffixNum === 0) return `${number}`;

        const suffix = suffixes[suffixNum - 1];
        const formattedNumber = number / (10 ** (suffixNum * 3));

        return formattedNumber + suffix;
    }
};