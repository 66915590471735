import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

const ResultPage = lazy(() => import('../../container/pages/ResultPage'));
const ClientPage = lazy(() => import('../../container/pages/result/ClientPage'));
const BillPage = lazy(() => import('../../container/pages/result/BillPage'));
const BillMemberPage = lazy(() => import('../../container/pages/result/BillMemberPage'));

function ResultRoute() {
  return (
    <Routes>
      <Route index element={<ResultPage />} />
      <Route path="/client" element={<ClientPage />} />
      <Route path="/bill" element={<BillPage />} />
      <Route path="/bill-member" element={<BillMemberPage />} />
    </Routes>
  );
}

export default ResultRoute;
