import { UilDollarAlt, UilClipboardNotes, UilFileCheckAlt, UilUserArrows, UilUser, UilBill, UilMegaphone, UilAwardAlt, UilStar, UilNerd, UilSetting, UilHouseUser } from '@iconscout/react-unicons';
import { NavLink } from 'react-router-dom';

function getItem(label, key, icon, children, type) {
    return {
        key,
        icon,
        children,
        label,
        type,
    };
}

const DefaultMenu = (toggleCollapsed, path, topMenu) => {
    const items = [
        getItem(
            <NavLink onClick={toggleCollapsed} to={`${path}/`}>
                แทงหวย
            </NavLink>,
            'main',
            !topMenu && (
                <NavLink className="menuItem-iocn" to={`${path}/`}>
                    <UilDollarAlt />
                </NavLink>
            ),
        ),
        getItem(
            <NavLink onClick={toggleCollapsed} to={`${path}/rule`}>
                กฎและกติกา
            </NavLink>,
            'rule',
            !topMenu && (
                <NavLink className="menuItem-iocn" to={`${path}/rule`}>
                    <UilClipboardNotes />
                </NavLink>
            ),
        ),
        getItem(
            <NavLink onClick={toggleCollapsed} to={`${path}/bet-report`}>
                รายการแทง
            </NavLink>,
            'bet-report',
            !topMenu && (
                <NavLink className="menuItem-iocn" to={`${path}/bet-report`}>
                    <UilFileCheckAlt />
                </NavLink>
            ),
        ),
        getItem(
            <NavLink onClick={toggleCollapsed} to={`${path}/setting`}>
                ตั้งค่า
            </NavLink>,
            'setting',
            !topMenu && (
                <NavLink className="menuItem-iocn" to={`${path}/setting`}>
                    <UilSetting />
                </NavLink>
            ),
        ),
        getItem(
            <NavLink onClick={toggleCollapsed} to={`${path}/rate`}>
                ตั้งค่าเรท
            </NavLink>,
            'rate',
            !topMenu && (
                <NavLink className="menuItem-iocn" to={`${path}/rate`}>
                    <UilStar />
                </NavLink>
            ),
        ),
        getItem(
            'สมาชิก',
            'members',
            !topMenu && <UilUserArrows />, [
            getItem(
                <NavLink onClick={toggleCollapsed} to={`${path}/supporter`}>
                    ผู้ช่วย
                </NavLink>,
                'supporter',
                null,
            ),
            getItem(
                <NavLink onClick={toggleCollapsed} to={`${path}/announcer`}>
                    สมาชิกสำหรับออกผล
                </NavLink>,
                'announcer',
                null,
            ),
            getItem(
                <NavLink onClick={toggleCollapsed} to={`${path}/header`}>
                    เฮดเดอร์
                </NavLink>,
                'header',
                null,
            ),
            getItem(
                <NavLink onClick={toggleCollapsed} to={`${path}/agent`}>
                    เอเย่น
                </NavLink>,
                'agent',
                null,
            ),
            getItem(
                <NavLink onClick={toggleCollapsed} to={`${path}/member`}>
                    สมาชิก
                </NavLink>,
                'member',
                null,
            ),
        ]),
        getItem(
            <NavLink onClick={toggleCollapsed} to={`${path}/supporter`}>
                ผู้ช่วย
            </NavLink>,
            'supporter',
            !topMenu && (
                <NavLink className="menuItem-iocn" to={`${path}/supporter`}>
                    <UilHouseUser />
                </NavLink>
            ),
        ),
        getItem(
            <NavLink onClick={toggleCollapsed} to={`${path}/announcer`}>
                สมาชิกสำหรับออกผล
            </NavLink>,
            'announcer',
            !topMenu && (
                <NavLink className="menuItem-iocn" to={`${path}/announcer`}>
                    <UilNerd />
                </NavLink>
            ),
        ),
        getItem(
            <NavLink onClick={toggleCollapsed} to={`${path}/header`}>
                เฮดเดอร์
            </NavLink>,
            'header',
            !topMenu && (
                <NavLink className="menuItem-iocn" to={`${path}/header`}>
                    <UilUserArrows />
                </NavLink>
            ),
        ),
        getItem(
            <NavLink onClick={toggleCollapsed} to={`${path}/agent`}>
                เอเย่น
            </NavLink>,
            'agent',
            !topMenu && (
                <NavLink className="menuItem-iocn" to={`${path}/agent`}>
                    <UilUserArrows />
                </NavLink>
            ),
        ),
        getItem(
            <NavLink onClick={toggleCollapsed} to={`${path}/member`}>
                สมาชิก
            </NavLink>,
            'member',
            !topMenu && (
                <NavLink className="menuItem-iocn" to={`${path}/member`}>
                    <UilUser />
                </NavLink>
            ),
        ),
        getItem(
            <NavLink onClick={toggleCollapsed} to={`${path}/result`}>
                บัญชีการเงิน
            </NavLink>,
            'result',
            !topMenu && (
                <NavLink className="menuItem-iocn" to={`${path}/result`}>
                    <UilBill />
                </NavLink>
            ),
        ),
        getItem(
            <NavLink onClick={toggleCollapsed} to={`${path}/announce`}>
                ออกผล
            </NavLink>,
            'announce',
            !topMenu && (
                <NavLink className="menuItem-iocn" to={`${path}/announce`}>
                    <UilMegaphone />
                </NavLink>
            ),
        ),
        getItem(
            <NavLink onClick={toggleCollapsed} to={`${path}/lotto-result`}>
                ตรวจสอบผลรางวัล
            </NavLink>,
            'lotto-result',
            !topMenu && (
                <NavLink className="menuItem-iocn" to={`${path}/lotto-result`}>
                    <UilAwardAlt />
                </NavLink>
            ),
        ),
    ];

    return items;
}

export const SuperAdminRoleMenu = (toggleCollapsed, path, topMenu) => {
    const menuItems = DefaultMenu(toggleCollapsed, path, topMenu);
    const menues = [0, 1, 2, 3, 4, 5, 11, 12, 13];
    const items = [];

    menues.forEach(v => {
        items.push(menuItems[v]);
    });

    return items;
};

export const AnnouncerRoleMenu = (toggleCollapsed, path, topMenu) => {
    const menuItems = DefaultMenu(toggleCollapsed, path, topMenu);

    return [menuItems[12]];
}

export const HeaderRoleMenu = (toggleCollapsed, path, topMenu) => {
    const menuItems = DefaultMenu(toggleCollapsed, path, topMenu);
    const menues = [0, 1, 2, 9, 10, 11, 13];
    const items = [];

    menues.forEach(v => {
        items.push(menuItems[v]);
    });

    return items;
}

export const AgentRoleMenu = (toggleCollapsed, path, topMenu) => {
    const menuItems = DefaultMenu(toggleCollapsed, path, topMenu);
    const menues = [0, 1, 2, 10, 11, 13];
    const items = [];

    menues.forEach(v => {
        items.push(menuItems[v]);
    });

    return items;
}

export const SupporterRoleMenu = (toggleCollapsed, path, topMenu) => {
    const menuItems = DefaultMenu(toggleCollapsed, path, topMenu);

    return [menuItems[11]];
}