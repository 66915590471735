import Cookies from 'js-cookie';
import { apiClient } from "./apiClient";

export const login = async (username, password) => {
    const response = await apiClient.post('/auth/login/admin', { username, password });
    return response;
}

export const Profile = async () => {
    const response = await apiClient.post('/member/profile', null, {
        headers: { Authorization: `Bearer ${Cookies.get('access_token')}` }
    });

    return response;
}