import UilAngleDown from '@iconscout/react-unicons/icons/uil-angle-down';
import UilSignout from '@iconscout/react-unicons/icons/uil-signout';
import UisKeySkeleton from '@iconscout/react-unicons/icons/uil-key-skeleton';
import { Avatar, Form, Input, Modal, Space, Spin } from 'antd';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { UilSync } from '@iconscout/react-unicons';
import Cookies from 'js-cookie';
import { InfoWraper, UserDropDwon } from './auth-info-style';
import { logout } from '../../../stores/authSlice';
import { utils } from '../../../service/utils';
import Heading from '../../heading/heading';
import { Popover } from '../../popup/popup';
import avatar from '../../../static/img/avatar/avatar.svg';
import { Profile } from '../../../service/auth';
import { Button } from '../../buttons/buttons';
import Notification from '../../notification/notification';
import { changePassword } from '../../../service/member';

const AuthInfo = React.memo(() => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [form] = Form.useForm();

  const role = Cookies.get('role');
  const [changePasswordModal, setChangePasswordModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const profileQuery = useQuery({
    queryKey: 'profile',
    queryFn: Profile,
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: false
  });

  const changePasswordMutation = useMutation({
    mutationFn: (data) => changePassword(data)
  })

  const SignOut = (e) => {
    e.preventDefault();
    // logout()
    dispatch(logout(() => navigate('/')));
  };

  const onChangePasswordOpen = () => {
    form.resetFields();
    setChangePasswordModal(true);
  }

  const onsubmitChangePassword = () => {
    form.validateFields().then(async (values) => {
      setLoading(true);
      try {
        changePasswordMutation.mutate({ ...values });
        setLoading(false);
        setChangePasswordModal(false);
        Notification("success", "เปลี่ยนรหัสผ่านเรียบร้อยแล้ว");
      } catch (error) {
        setLoading(false);
        Notification("error", "ไม่สามารถเปลี่ยนรหัสผ่านได้กรุณาลองใหม่");
      }
    });
  }

  const userContent = (
    <UserDropDwon>
      <div className="user-dropdwon">
        <figure className="user-dropdwon__info">
          {/* <img src={require('../../../static/img/avatar/chat-auth.png')} alt="" /> */}
          <figcaption>
            {
              !profileQuery.isLoading ?
                <Heading as="h5">เครดิต: {role === 'header' || role === 'agent' ? `${utils.numberWithCommas(`${profileQuery.data.data.credit}`)}฿` : '$$$'} <Button type="dark" shape="circle" size="extra-small" onClick={() => queryClient.invalidateQueries(['profile'])}><UilSync /></Button></Heading>
                : <></>
            }
            <Heading as="h5">ระดับ: {Cookies.get('role')}</Heading>
            <Heading as="h5">หุ้น: {`${Cookies.get('share')}%`}</Heading>
          </figcaption>
        </figure>
        <ul className="user-dropdwon__links">
          <li>
            <Link to="#" onClick={() => onChangePasswordOpen()}>
              <UisKeySkeleton /> เปลี่ยนรหัสผ่าน
            </Link>
          </li>
        </ul>
        <Link className="user-dropdwon__bottomAction" onClick={SignOut} to="#">
          <UilSignout /> ออกจากระบบ
        </Link>
      </div>
    </UserDropDwon>
  );

  return (
    <InfoWraper>
      <div className="ninjadash-nav-actions__item ninjadash-nav-actions__author">
        <Popover placement="bottomRight" content={userContent} action="click">
          <Link to="#" className="ninjadash-nav-action-link">
            <Avatar src={avatar} />
            {
              !profileQuery.isLoading ?
                <span className="ninjadash-nav-actions__author--name" style={{ fontWeight: 'bold' }}>{Cookies.get('name')} {role === 'header' || role === 'agent' ? `${utils.numberWithCommas(`${profileQuery.data.data.credit}`)}฿` : '$$$'}</span> : <></>}
            <UilAngleDown />
          </Link>
        </Popover>
      </div>

      <Modal title="เปลี่ยนรหัสผ่าน"
        maskClosable={false}
        onCancel={() => setChangePasswordModal(false)}
        open={changePasswordModal}
        footer={false}
      >
        <Spin spinning={loading}>
          <Form
            form={form}
            name="change-password"
          >
            <Form.Item
              name="oldPassword"
              rules={[
                {
                  required: true,
                  message: 'กรุณาใส่รหัสผ่านเก่า',
                },
              ]}
            >
              <Input className="input-password" maxLength={32} prefix={<UisKeySkeleton />} type="password" placeholder="รหัสผ่านเก่า" />
            </Form.Item>

            <Form.Item
              name="newPassword"
              rules={[
                {
                  required: true,
                  message: 'กรุณาใส่รหัสผ่านใหม่',
                },
              ]}
            >
              <Input className="input-password" maxLength={32} prefix={<UisKeySkeleton />} type="password" placeholder="รหัสผ่านใหม่" />
            </Form.Item>
            <Form.Item>
              <div style={{ textAlign: 'right' }}>
                <Space>
                  <Button onClick={() => setChangePasswordModal(false)} type="danger">ยกเลิก</Button>
                  <Button onClick={() => onsubmitChangePassword()} type="primary">บันทึก</Button>
                </Space>

              </div>
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    </InfoWraper>
  );
});

export default AuthInfo;
